<template>
  <div class="home" :class="{ full: !isHomeInit }">
    <div class="home-introduction" id="introduction">
      <div class="home-introduction-description">
        <div class="home-introduction-description-field">
          <div class="home-introduction-description-field-title">主要發展</div>
          <h1 class="home-introduction-description-field-content">
            <div class="home-introduction-description-field-content-word">
              公司主要從事停車管理服務業
            </div>
            <div class="home-introduction-description-field-content-word">
              至今SmartPark System管理系統已服務數百個停車場站
            </div>
            <div class="home-introduction-description-field-content-word">
              並開發PACA雲端資訊整合系統，提供企業最佳解決方案
            </div>
          </h1>
        </div>
        <div class="home-introduction-description-field text-right">
          <div class="home-introduction-description-field-title">延伸領域</div>
          <div class="home-introduction-description-field-content">
            <div class="home-introduction-description-field-content-word">
              我們同時提供系統開發服務
            </div>
            <div class="home-introduction-description-field-content-word">
              為客戶規畫與打造所需的功能及流程
            </div>
            <div class="home-introduction-description-field-content-word">
              提供專業友善的系統介面以貼近使用者需求
            </div>
            <div class="home-introduction-description-field-content-word">
              協助客戶達到開創業務之目標
            </div>
          </div>
        </div>
      </div>
      <div class="home-introduction-img"></div>
      <div class="home-introduction-scroll">
        <div class="home-introduction-scroll-text">SCROLL</div>
        <div class="home-introduction-scroll-arrow"></div>
      </div>
    </div>
    <div class="home-product" id="product">
      <div class="home-product-title">停車場管理系統：大小型停車場都適用</div>
      <div class="home-product-content">
        <div class="home-product-content-item">
          <div class="home-product-content-item-title">多車種</div>
          <div class="home-product-content-item-text">
            提供轎車、機車、重機、電動車...等，各類車種獨立計費功能
          </div>
          <div class="home-product-content-item-img"></div>
        </div>
        <div class="home-product-content-item">
          <div class="home-product-content-item-title">多重計費</div>
          <div class="home-product-content-item-text">
            支援平日、假日、日間、夜間、隔夜...等，多時段計費方式。提供臨停、月租、會員，各種計費方案
          </div>
          <div class="home-product-content-item-img"></div>
        </div>
        <div class="home-product-content-item">
          <div class="home-product-content-item-title">多重折扣</div>
          <div class="home-product-content-item-text">
            配合店家、賣場、銀行信用卡...等活動，進行優惠折抵
          </div>
          <div class="home-product-content-item-img"></div>
        </div>
        <div class="home-product-content-item">
          <div class="home-product-content-item-title">可單機作業</div>
          <div class="home-product-content-item-text">
            具單機獨立作業能力，網路中斷後，仍可繼續運作。不論上線、下線皆可輕鬆控管
          </div>
          <div class="home-product-content-item-img"></div>
        </div>
        <div class="home-product-content-item">
          <div class="home-product-content-item-title">黑名單控制</div>
          <div class="home-product-content-item-text">
            針對特定或違規車輛，進行黑名單管制，安全掌控車輛進出
          </div>
          <div class="home-product-content-item-img"></div>
        </div>
        <div class="home-product-content-item">
          <div class="home-product-content-item-title">停車費試算</div>
          <div class="home-product-content-item-text">
            線上停車費查詢，讓你不論身處何處，只要輸入車牌號碼，立即查看已停留時數及應繳停車費用
          </div>
          <div class="home-product-content-item-img"></div>
        </div>
        <div class="home-product-content-item">
          <div class="home-product-content-item-title">憑證整合</div>
          <div class="home-product-content-item-text">
            整合各場站所有憑證，讓您一站掌控所有資訊只要一個帳號，輕鬆瀏覽所有場站現況，是策略分析、狀況處理的最佳幫手
          </div>
          <div class="home-product-content-item-img"></div>
        </div>
      </div>
    </div>
    <div class="home-unify" id="unify">
      <div class="home-unify-pay" v-if="lastSecondWeekDaysIncome.length != 0">
        <div class="home-unify-pay-area--1">
          <div class="home-unify-pay-title">營收分析</div>
          <div class="home-unify-pay-last-total">
            <div class="home-unify-pay-last-total-money">
              {{
                [parseInt(lastSecondWeekDaysIncome[13].total)].toLocaleString()
              }}
            </div>
            <div class="home-unify-pay-last-total-day">
              {{ lastSecondWeekDaysIncome[13].date }} 收入
            </div>
          </div>
          <div class="home-unify-pay-last-count">
            <div class="home-unify-pay-last-count-item">
              <div class="home-unify-pay-last-count-item-label">現金</div>
              <div class="home-unify-pay-last-count-item-value">
                {{ [paysCompare.aps.value].toLocaleString() }}
              </div>
              <div
                class="home-unify-pay-last-count-item-icon"
                :class="[paysCompare.aps.icon]"
              ></div>
            </div>
            <div class="home-unify-pay-last-count-item">
              <div class="home-unify-pay-last-count-item-label">會員</div>
              <div class="home-unify-pay-last-count-item-value">
                {{ [paysCompare.fetc.value].toLocaleString() }}
              </div>
              <div
                class="home-unify-pay-last-count-item-icon"
                :class="[paysCompare.fetc.icon]"
              ></div>
            </div>
            <div class="home-unify-pay-last-count-item">
              <div class="home-unify-pay-last-count-item-label">刷卡</div>
              <div class="home-unify-pay-last-count-item-value">
                {{ [paysCompare.card.value].toLocaleString() }}
              </div>
              <div
                class="home-unify-pay-last-count-item-icon"
                :class="[paysCompare.card.icon]"
              ></div>
            </div>
            <div class="home-unify-pay-last-count-item">
              <div class="home-unify-pay-last-count-item-label">儲值</div>
              <div class="home-unify-pay-last-count-item-value">
                {{ [paysCompare.deposit.value].toLocaleString() }}
              </div>
              <div
                class="home-unify-pay-last-count-item-icon"
                :class="[paysCompare.deposit.icon]"
              ></div>
            </div>
            <div class="home-unify-pay-last-count-item">
              <div class="home-unify-pay-last-count-item-label">行動支付</div>
              <div class="home-unify-pay-last-count-item-value">
                {{ [paysCompare.paytaipei.value].toLocaleString() }}
              </div>
              <div
                class="home-unify-pay-last-count-item-icon"
                :class="[paysCompare.paytaipei.icon]"
              ></div>
            </div>
            <div class="home-unify-pay-last-count-description">
              與前一日相比
            </div>
          </div>
        </div>
        <div class="home-unify-pay-area--2">
          <div class="home-unify-pay-chart">
            <div class="home-unify-pay-chart-title">營收預測</div>
            <apexchart
              class="home-unify-pay-chart-item"
              v-if="paySeries[0].data.length != 0"
              type="line"
              height="320"
              :options="payChartOptions"
              :series="paySeries"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="home-unify-report">
        <div class="home-unify-report-top">
          <div class="home-unify-report-top-item identity">
            <div class="home-unify-report-top-item-title">在場客戶</div>
            <div class="home-unify-report-top-item-identity">
              <apexchart
                v-if="pieSeries.length != 0"
                type="radialBar"
                height="350"
                :options="pieChartOptions"
                :series="pieSeries"
              ></apexchart>
            </div>
          </div>
          <div class="home-unify-report-top-item inout">
            <div class="home-unify-report-top-item-title">每日車流量</div>
            <div class="home-unify-report-top-item-inout">
              <apexchart
                v-if="lineSeries.length != 0"
                type="line"
                height="350"
                :options="lineChartOptions"
                :series="lineSeries"
              ></apexchart>
            </div>
          </div>
          <div class="home-unify-report-top-item host">
            <div class="home-unify-report-top-item-title">
              <span class="home-unify-report-top-item-title-name"
                >各時段流量</span
              >
              <select
                class="home-unify-report-top-item-title-select"
                v-model="timeCheckDay"
                @change="drowTimeCheckChart()"
              >
                <option
                  v-for="(item, index) in timeCheckDaysOption"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
              <span class="home-unify-report-top-item-title-description">
                <span
                  class="home-unify-report-top-item-title-description-label"
                ></span>
                {{ timeCheckDescription }}
              </span>
            </div>
            <div class="home-unify-report-top-item-host">
              <apexchart
                v-if="timeCheckSeries.length != 0"
                type="area"
                height="350"
                :options="timeCheckOptions"
                :series="timeCheckSeries"
              ></apexchart>
            </div>
          </div>
        </div>
        <div class="home-unify-report-bottom">
          <div class="home-unify-report-bottom-item useing">
            <div class="home-unify-report-bottom-item-title">
              <div class="home-unify-report-bottom-item-title-text">
                使用率概況
              </div>
            </div>
            <div class="home-unify-report-bottom-item-control">
              <span
                class="home-unify-report-bottom-item-control-text"
                :class="{ sunColor: !isViewNightHeatData }"
                >日間</span
              >
              <label>
                <input
                  type="checkbox"
                  class="checkbox"
                  v-model="isViewNightHeatData"
                  @change="switchHeatData"
                />
                <span class="btn-box">
                  <span class="btn"></span>
                </span>
              </label>
              <span
                class="home-unify-report-bottom-item-control-text"
                :class="{ nightColor: isViewNightHeatData }"
                >夜間</span
              >
            </div>
            <div class="home-unify-report-bottom-item-useing">
              <apexchart
                v-if="heatSeries.length != 0"
                :key="heatKey"
                type="heatmap"
                height="300"
                :options="heatChartOptions"
                :series="heatSeries"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="home-unify-map">
        <div class="home-unify-map-title">服務場站分布</div>
        <div class="home-unify-map-item">
          <div class="home-unify-map-item-image"></div>
          <div class="home-unify-map-item-list">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#cityListModal"
              class="home-unify-map-item-list-city"
              v-for="(item, index) in lotsCity"
              :key="index"
              @click="openModal(index)"
            >
              <div class="home-unify-map-item-list-city-name">{{ index }}</div>
              <div class="home-unify-map-item-list-city-count">
                {{ item.length }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="home-about" id="about">
      <div class="home-about-connect">
        <div class="home-about-connect-title">聯絡我們</div>
        <div class="home-about-connect-content">
          <div class="home-about-connect-content-group">
            <div class="home-about-connect-content-group-item">
              <div
                class="home-about-connect-content-group-item-img svg-email"
              ></div>
            </div>
            <div class="home-about-connect-content-group-item">
              <div class="home-about-connect-content-group-item-text">
                service<span class="d-none">不接受廣告信件</span>@berryan.com
              </div>
            </div>
          </div>
          <div class="home-about-connect-content-group">
            <div class="home-about-connect-content-group-item">
              <div
                class="home-about-connect-content-group-item-img svg-phone"
              ></div>
            </div>
            <div class="home-about-connect-content-group-item">
              <div class="home-about-connect-content-group-item-text">
                02-26968766
              </div>
              <div class="home-about-connect-content-group-item-text">
                (週一 - 五 9am - 6pm)
              </div>
            </div>
          </div>
          <div class="home-about-connect-content-group">
            <div class="home-about-connect-content-group-item">
              <div
                class="home-about-connect-content-group-item-img svg-home"
              ></div>
            </div>
            <div class="home-about-connect-content-group-item">
              <div class="home-about-connect-content-group-item-text">
                新北市汐止區新台五路一段106號18樓
              </div>
              <div class="home-about-connect-content-group-item-text">
                (東方科學園區B棟)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-about-map" id="about-map"></div>
    </div>
    <div class="modal" tabindex="-1" id="cityListModal">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ openCity }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body" v-if="lotsCity[openCity]">
            <div
              class="city-list"
              v-for="(item, index) in lotsCity[openCity]"
              :key="index"
            >
              {{ index + 1 }}.{{ item.address }}
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Home",
    data() {
      let lastDayTotalCarsInOut = {};
      let lastDayHoursCarsInOut = [];
      let lastSecondWeekDaysCarsInOut = [];
      let lastSecondWeekDaysIncome = [];
      let lastDayTotalCarsInTotal = 0;
      let lastWeekMaxValue = 0;
      let paysCompare = {
        aps: {
          title: "現金",
          value: "",
          icon: "top_arrow",
        },
        fetc: {
          title: "會員",
          value: "",
          icon: "top_arrow",
        },
        card: {
          title: "刷卡",
          value: "",
          icon: "top_arrow",
        },
        deposit: {
          title: "儲值",
          value: "",
          icon: "top_arrow",
        },
        paytaipei: {
          title: "行動支付",
          value: "",
          icon: "top_arrow",
        },
      };
      let effectBarX = [];
      let effectBarY = [];
      let effectBar = [];
      let pieSeries = [];
      let pieSeriesTotal = 0;
      let pieChartOptions = {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E"],
        labels: ["月租", "臨停", "會員"],
        legend: {
          show: true,
          floating: true,
          fontSize: "16px",
          position: "left",
          offsetX: 35,
          offsetY: 55,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return (
              seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
            );
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      };
      let lineSeries = [];
      let lineChartOptions = {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["週一", "週二", "週三", "週四", "週五", "週六", "週日"],
        },
        yaxis: {
          min: 70000,
          max: 110000,
          labels: {
            offsetX: -20,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      };
      let heatSeries = [];
      let heatChartOptions = {
        chart: {
          height: 300,
          type: "heatmap",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#E6DF4C"],
        toolbar: {
          show: false,
        },
      };
      let paySeries = [
        {
          name: "總收入",
          data: [],
        },
      ];
      let payChartOptions = {
        chart: {
          height: 320,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        forecastDataPoints: {
          count: 4,
        },
        fill: {
          type: "gradient",
          colors: "#77AEDE",
          gradient: {
            shade: "dark",
            gradientToColors: ["#77D0DE"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        annotations: {
          points: [],
        },
        tooltip: {
          enabled: false,
        },
      };
      let sunHeadtData = [];
      let nightHeatDate = [];
      let isViewNightHeatData = false;
      let heatKey = 0;
      let timeCheckOptions = {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        labels: ["7~8", "9~10", "11~12", "13~14", "15~16", "17~18", "19~20"],
        annotations: {
          xaxis: [
            {
              x: "9~10",
              label: {
                show: true,
                text: "現在",
                orientation: "horizontal",
                borderColor: "none",
                style: {
                  color: "Red",
                  background: "transparent",
                  fontSize: "18px",
                  fontWeight: "boid",
                },
              },
            },
          ],
        },
      };
      let timeCheckSeries = [];
      let timeCheckDescription = "目前非熱門時段";
      let timeCheckDay = 0;
      let timeCheckDaysOption = [
        { label: "週日", value: 0 },
        { label: "週一", value: 1 },
        { label: "週二", value: 2 },
        { label: "週三", value: 3 },
        { label: "週四", value: 4 },
        { label: "週五", value: 5 },
        { label: "週六", value: 6 },
      ];
      let lotsCity = {};
      let openCity = "台北市";
      return {
        productActive: 1,
        lastDayTotalCarsInOut,
        lastDayHoursCarsInOut,
        lastSecondWeekDaysCarsInOut,
        lastDayTotalCarsInTotal,
        lastSecondWeekDaysIncome,
        paysCompare,
        paySeries,
        payChartOptions,
        effectBarX,
        effectBarY,
        effectBar,
        lastWeekMaxValue,
        pieSeries,
        pieSeriesTotal,
        pieChartOptions,
        lineSeries,
        lineChartOptions,
        heatSeries,
        heatChartOptions,
        sunHeadtData,
        nightHeatDate,
        isViewNightHeatData,
        heatKey,
        timeCheckOptions,
        timeCheckSeries,
        timeCheckDescription,
        timeCheckDay,
        timeCheckDaysOption,
        lotsCity,
        openCity,
      };
    },
    methods: {
      scrollXInitial() {
        window.addEventListener(
          "mousewheel",
          function (e) {
            if (!document.querySelector(".modal-open")) {
              let distanceX = e.deltaY;
              document.querySelector(".home").scrollLeft += distanceX / 2;
            }
          }.bind(this)
        );
      },
      productSwitch(item) {
        this.productActive = item;
      },
      getLastDayHoursCarsInOut() {
        let vm = this;
        $.ajax({
          url: "https://s3.ap-northeast-1.amazonaws.com/s3.paca.com.tw/period_report/stat/last_flow_per_hour.json",
          method: "GET",
          success: function (res) {
            vm.lastDayHoursCarsInOut = res;
            vm.drawHeatChart();
            vm.timeCheckDay = new Date().getDay();
            vm.drowTimeCheckChart();
          },
          error: function (err) {
            console.log(err);
          },
        });
      },
      getLastSecondWeekDaysCarsInOut() {
        let vm = this;
        $.ajax({
          url: "https://s3.ap-northeast-1.amazonaws.com/s3.paca.com.tw/period_report/stat/last_flow_per_day.json",
          method: "GET",
          success: function (res) {
            vm.lastDayTotalCarsInOut = res[0];
            vm.drawIdentityChart();
            vm.lastSecondWeekDaysCarsInOut = res;
            vm.drawInOutChart();
          },
          error: function (err) {
            console.log(err);
          },
        });
      },
      getLastSecondWeekDaysIncome() {
        let vm = this;
        $.ajax({
          url: "https://s3.ap-northeast-1.amazonaws.com/s3.paca.com.tw/period_report/stat/last_income_per_day.json",
          method: "GET",
          success: function (res) {
            vm.lastSecondWeekDaysIncome = res;
            let compare = function (item) {
              vm.paysCompare[item].value =
                parseInt(vm.lastSecondWeekDaysIncome[0][item]) -
                parseInt(vm.lastSecondWeekDaysIncome[1][item]);
              if (vm.paysCompare[item].value < 0) {
                vm.paysCompare[item].value = vm.paysCompare[item].value * -1;
                vm.paysCompare[item].icon = "bottom_arrow";
              } else if (vm.paysCompare[item].value == 0) {
                vm.paysCompare[item].icon = "remove";
              }
            };
            compare("aps");
            compare("fetc");
            vm.lastSecondWeekDaysIncome[0].card =
              parseInt(vm.lastSecondWeekDaysIncome[0].exit_pay) +
              parseInt(vm.lastSecondWeekDaysIncome[0].easycard);
            vm.lastSecondWeekDaysIncome[1].card =
              parseInt(vm.lastSecondWeekDaysIncome[1].exit_pay) +
              parseInt(vm.lastSecondWeekDaysIncome[1].easycard);
            compare("card");
            compare("deposit");
            compare("paytaipei");
            vm.drawPayChart();
          },
          error: function (err) {
            console.log(err);
          },
        });
      },
      drawIdentityChart() {
        this.pieSeriesTotal =
          parseInt(this.lastDayTotalCarsInOut.in_member_car) +
          parseInt(this.lastDayTotalCarsInOut.in_hourly_car) +
          parseInt(this.lastDayTotalCarsInOut.in_fetc_car);
        this.pieSeries = [
          Math.round(
            (this.lastDayTotalCarsInOut.in_member_car / this.pieSeriesTotal) *
              100
          ),
          Math.round(
            (this.lastDayTotalCarsInOut.in_hourly_car / this.pieSeriesTotal) *
              100
          ),
          Math.round(
            (this.lastDayTotalCarsInOut.in_fetc_car / this.pieSeriesTotal) * 100
          ),
        ];
      },
      drawInOutChart() {
        let lineSeries = JSON.parse(
          JSON.stringify(this.lastSecondWeekDaysCarsInOut)
        )
          .splice(0, 7)
          .reverse();
        this.lineSeries = [
          {
            name: "入場流量",
            data: [],
          },
          {
            name: "出場流量",
            data: [],
          },
        ];
        let minValue = 0;
        let maxValue = 0;
        let days = [];
        for (let i = 0; i < 7; i++) {
          lineSeries[i].in_total = parseInt(lineSeries[i].in_total);
          lineSeries[i].out_total = parseInt(lineSeries[i].out_total);
          this.lineSeries[0].data[i] = lineSeries[i].in_total;
          this.lineSeries[1].data[i] = lineSeries[i].out_total;
          if (minValue == 0 || minValue > lineSeries[i].in_total) {
            minValue = lineSeries[i].in_total;
          }
          if (maxValue < lineSeries[i].in_total) {
            maxValue = lineSeries[i].in_total;
          }
          if (minValue > lineSeries[i].out_total) {
            minValue = lineSeries[i].out_total;
          }
          if (maxValue < lineSeries[i].out_total) {
            maxValue = lineSeries[i].out_total;
          }
          days.push(lineSeries[i].date.split("").splice(5).join(""));
        }
        minValue = minValue * 0.85;
        maxValue = maxValue * 1.15;
        this.lineChartOptions.yaxis.min = Math.round(minValue);
        this.lineChartOptions.yaxis.max = Math.round(maxValue);
        this.lineChartOptions.xaxis.categories = days;
      },
      drawHeatChart() {
        let resultArray = Object.values(this.lastDayHoursCarsInOut)
          .splice(1, 7)
          .reverse();
        this.sunHeadtData = [];
        this.nightHeatDate = [];
        resultArray.forEach(
          function (item) {
            let sunPushItem = {
              name: "",
              data: [],
            };
            let nightPushItem = {
              name: "",
              data: [],
            };
            sunPushItem.name = item.date;
            nightPushItem.name = item.date;
            let hour = 0;
            for (hour; hour < 24; hour++) {
              if (hour < 8 || hour > 19) {
                nightPushItem.data.push({
                  x: `${String(hour).padStart(2, "0")}:00`,
                  y: item.hours[hour].in_total,
                });
              } else {
                sunPushItem.data.push({
                  x: `${String(hour).padStart(2, "0")}:00`,
                  y: item.hours[hour].in_total,
                });
              }
            }
            let tempNightArray = nightPushItem.data.splice(8);
            nightPushItem.data = tempNightArray.concat(nightPushItem.data);
            this.sunHeadtData.push(sunPushItem);
            this.nightHeatDate.push(nightPushItem);
          }.bind(this)
        );
        this.switchHeatData();
      },
      drawPayChart() {
        this.paySeries[0].data = [];
        this.lastSecondWeekDaysIncome.splice(14);
        let dataArray = [];
        this.payChartOptions.annotations.points = [
          {
            x: 275,
            y: this.lastSecondWeekDaysIncome[4].total,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2,
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560",
              },
              text: "今天",
            },
          },
        ];
        this.lastSecondWeekDaysIncome.reverse().forEach(
          function (item) {
            this.paySeries[0].data.push(item.total);
            dataArray.push(item.date);
          }.bind(this)
        );
        this.payChartOptions.xaxis.categories = dataArray;
      },
      createEffectMap() {
        let vm = this;
        $.ajax({
          methods: "GET",
          url: "https://epk-admin.fetc.net.tw/api/lots",
          success: function (res) {
            vm.lotsCity = {};
            res.forEach(function (item) {
              if (vm.lotsCity[item.city]) {
                vm.lotsCity[item.city].push(item);
              } else if (item.city) {
                vm.lotsCity[item.city] = [];
              }
            });
          },
          error: function (err) {
            console.log(err);
          },
        });
      },
      switchHeatData() {
        if (this.isViewNightHeatData) {
          this.heatChartOptions.colors = ["#0F3F59"];
          this.heatSeries = JSON.parse(JSON.stringify(this.nightHeatDate));
          this.heatKey += 1;
        } else {
          this.heatChartOptions.colors = ["#E6DF4C"];
          this.heatSeries = JSON.parse(JSON.stringify(this.sunHeadtData));
          this.heatKey += 1;
        }
      },
      drowTimeCheckChart() {
        let changeDay = this.timeCheckDay;
        let timeCheckData = Object.values(
          JSON.parse(JSON.stringify(this.lastDayHoursCarsInOut))
        );
        timeCheckData.shift();
        let week = 0;
        //diff = in - out
        let timeViewDataIn = {
          "1th": 0,
          "2th": 0,
          "3th": 0,
          "4th": 0,
          "5th": 0,
          "6th": 0,
          "7th": 0,
        };
        let timeViewDataOut = {
          "1th": 0,
          "2th": 0,
          "3th": 0,
          "4th": 0,
          "5th": 0,
          "6th": 0,
          "7th": 0,
        };
        let timeViewDataDiff = {
          "1th": 0,
          "2th": 0,
          "3th": 0,
          "4th": 0,
          "5th": 0,
          "6th": 0,
          "7th": 0,
        };
        let focusDay = timeCheckData.find(
          (item) => item.weekday == changeDay
        ).hours;
        focusDay.forEach(
          function (secItem) {
            let AddedtimeObjectFunc = function (series) {
              timeViewDataIn[series] =
                timeViewDataIn[series] + Number(secItem.in_total);
              timeViewDataOut[series] =
                timeViewDataOut[series] + Number(secItem.out_total);
              timeViewDataDiff[series] =
                timeViewDataIn[series] - timeViewDataOut[series];
            };
            if (Number(secItem.hour) < 7) {
            } else if (Number(secItem.hour) <= 8) {
              AddedtimeObjectFunc("1th");
            } else if (Number(secItem.hour) <= 10) {
              AddedtimeObjectFunc("2th");
            } else if (Number(secItem.hour) <= 12) {
              AddedtimeObjectFunc("3th");
            } else if (Number(secItem.hour) <= 14) {
              AddedtimeObjectFunc("4th");
            } else if (Number(secItem.hour) <= 16) {
              AddedtimeObjectFunc("5th");
            } else if (Number(secItem.hour) <= 18) {
              AddedtimeObjectFunc("6th");
            } else if (Number(secItem.hour) <= 20) {
              AddedtimeObjectFunc("7th");
            }
          }.bind(this)
        );
        let now_hour = new Date().getHours();
        let annotationsDemo = {
          xaxis: [
            {
              x: "9~10",
              label: {
                show: true,
                text: "現在",
                orientation: "horizontal",
                borderColor: "none",
                offsetY: -15,
                style: {
                  color: "Red",
                  background: "transparent",
                  fontSize: "18px",
                  fontWeight: "boid",
                },
              },
            },
          ],
        };
        if (Number(now_hour) < 7) {
          this.timeCheckDescription = "目前非熱門時段";
          annotationsDemo.xaxis = [];
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 8) {
          annotationsDemo.xaxis[0].x = "7~8";
          this.timeCheckDescription =
            timeViewDataDiff["1th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["1th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 10) {
          annotationsDemo.xaxis[0].x = "9~10";
          this.timeCheckDescription =
            timeViewDataDiff["2th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["2th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 12) {
          annotationsDemo.xaxis[0].x = "11~12";
          this.timeCheckDescription =
            timeViewDataDiff["3th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["3th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 14) {
          annotationsDemo.xaxis[0].x = "13~14";
          this.timeCheckDescription =
            timeViewDataDiff["4th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["4th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 16) {
          annotationsDemo.xaxis[0].x = "15~16";
          this.timeCheckDescription =
            timeViewDataDiff["5th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["5th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 18) {
          annotationsDemo.xaxis[0].x = "17~18";
          this.timeCheckDescription =
            timeViewDataDiff["6th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["6th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 20) {
          annotationsDemo.xaxis[0].x = "19~20";
          this.timeCheckDescription =
            timeViewDataDiff["7th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["7th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else {
          this.timeCheckDescription = "目前非熱門時段";
          annotationsDemo.xaxis = [];
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        }
        this.timeCheckSeries = [
          {
            name: "入場流量",
            data: Object.values(timeViewDataIn),
          },
          {
            name: "出場流量",
            data: Object.values(timeViewDataOut),
          },
        ];
      },
      openModal(city) {
        this.openCity = city;
      },
    },
    computed: {
      isHomeInit() {
        return this.$store.state.isHomeInit;
      },
    },
    created() {
      this.scrollXInitial();
    },
    mounted() {
      this.createEffectMap();
      this.getLastDayHoursCarsInOut();
      this.getLastSecondWeekDaysCarsInOut();
      this.getLastSecondWeekDaysIncome();
      this.$nextTick(() => {
        var map = L.map("about-map");
        // 設定經緯度座標
        map.setView(new L.LatLng(25.061418462908186, 121.64916841759354), 17);
        // 設定圖資來源
        var osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
        var osm = new L.TileLayer(osmUrl, { minZoom: 12, maxZoom: 18 });
        map.addLayer(osm);
        L.marker([25.061418462908186, 121.64916841759354], {
          title: "泊律恆有限公司",
        }).addTo(map);
      });
    },
  };
</script>
<style lang="scss">
  .home {
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;
    &.full {
      margin-left: 100px;
    }
    height: 100%;
    position: relative;
    background: url("~@/assets/home/home_background.svg") center center / cover
      no-repeat;
    &-introduction {
      position: relative;
      height: 100%;
      display: flex;
      padding: 0 100px;
      @media (max-height: 992px) {
        padding: 0 75px;
      }
      align-items: center;
      &-description {
        min-width: 900px;
        margin-right: 200px;
        @media (max-height: 992px) {
          min-width: 675px;
          margin-right: 150px;
        }
        position: relative;
        &-field {
          &:first-of-type {
            opacity: 0;
            margin-bottom: 80px;
            position: relative;
            @keyframes descriptionTopField {
              0% {
                left: -1800px;
                opacity: 0;
              }
              50% {
                left: -1000px;
                opacity: 0.3;
              }
              100% {
                left: 0;
                opacity: 1;
              }
            }
            animation: descriptionTopField 2s 1 ease-in forwards;
            @media (max-height: 1000px) {
              margin-bottom: 40px;
            }
          }
          &:last-of-type {
            position: relative;
            opacity: 0;
            @keyframes descriptionBottomField {
              0% {
                bottom: -1800px;
                opacity: 0;
              }
              50% {
                bottom: -1000px;
                opacity: 0.3;
              }
              100% {
                bottom: 0;
                opacity: 1;
              }
            }
            animation: descriptionBottomField 2s 1 ease-in forwards;
            animation-delay: 1s;
          }
          &.text-right {
            text-align: right;
          }
          &-title {
            font-size: 48px;
            line-height: 1.5;
            margin-bottom: 40px;
            color: #528cb1;
            @media (max-height: 992px) {
              font-size: 36px;
              margin-bottom: 30px;
            }
          }
          &-content {
            &-word {
              font-size: 36px;
              line-height: 2;
              @media (max-height: 992px) {
                font-size: 27px;
              }
            }
          }
        }
      }
      &-img {
        background: url("~@/assets/home/home_img.svg") center center / contain
          no-repeat;
        width: 830px;
        height: 560px;
        opacity: 0;
        @media (max-height: 992px) {
          width: 620px;
          height: 420px;
        }
        @keyframes descriptionImage {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        animation: descriptionImage 2s 1 ease-in forwards;
        animation-delay: 3s;
      }
      &-scroll {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 40px;
        right: 100px;
        @media (max-height: 992px) {
          right: 75px;
        }
        color: #528cb1;
        &-text {
          font-size: 28px;
          font-weight: bold;
          margin-right: 15px;
        }
        &-arrow {
          width: 60px;
          height: 17px;
          display: flex;
          &::before {
            content: "";
            background: currentColor;
            width: 15px;
            clip-path: polygon(
              0 10px,
              calc(100% - 15px) 10px,
              calc(100% - 15px) 0,
              100% 50%,
              calc(100% - 15px) 100%,
              calc(100% - 15px) calc(100% - 10px),
              0 calc(100% - 10px)
            );
            animation: a1 1s infinite linear;
            @keyframes a1 {
              90%,
              100% {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
    &-service {
      position: relative;
      height: 100%;
      flex-shrink: 0;
      &-img {
        height: 100%;
      }
    }
    &-unify {
      position: relative;
      min-height: 100%;
      display: flex;
      padding: 0 150px;
      flex-shrink: 0;
      overflow-y: hidden;
      align-items: center;
      &-pay {
        min-height: 840px;
        min-width: 440px;
        margin-right: 15px;
        &-area--1 {
          background-color: #c1e8f0;
          padding: 0 10px;
          border-radius: 10px;
          margin-bottom: 20px;
        }
        &-area--2 {
          background-color: #c1e8f0;
          padding: 0 10px;
          border-radius: 10px;
        }
        &-title {
          padding: 10px 0;
          font-size: 18px;
          font-weight: 900;
        }
        &-last-total {
          &-money {
            font-size: 32px;
          }
          &-day {
            color: #888;
            font-size: 14px;
          }
          padding: 10px 0;
          border-bottom: 1px solid #555;
        }
        &-last-count {
          padding: 48px 0;
          &-item {
            display: flex;
            align-items: center;
            line-height: 2;
            &-label {
              flex: 1;
            }
            &-value {
              margin-right: 20px;
            }
            &-icon {
              width: 20px;
              height: 20px;
              &.top_arrow {
                background: url("~@/assets/top_arrow.svg") center center /
                  contain no-repeat;
              }
              &.bottom_arrow {
                background: url("~@/assets/bottom_arrow.svg") center center /
                  contain no-repeat;
              }
              &.remove {
                background: url("~@/assets/remove.svg") center center / contain
                  no-repeat;
              }
            }
          }
          &-description {
            text-align: right;
            color: #888;
            font-size: 14px;
          }
        }
        &-chart {
          &-title {
            padding: 10px 0;
            font-size: 18px;
            font-weight: 900;
          }
          &-item {
            padding: 20px 0;
          }
        }
      }
      &-report {
        display: flex;
        flex-direction: column;
        &-top {
          display: flex;
          &-item {
            min-width: 440px;
            border-radius: 10px;
            padding: 10px;
            margin-right: 15px;
            &:last-of-type {
              margin-right: 0;
            }
            &-title {
              font-size: 18px;
              font-weight: 900;
              display: flex;
              align-items: center;
              &-select {
                background-color: transparent;
                border: none;
                font-size: 16px;
                margin: 0 20px;
                cursor: pointer;
                &:focus-visible {
                  outline: none;
                }
              }
              &-description {
                display: flex;
                font-size: 14px;
                align-items: center;
                &-label {
                  width: 14px;
                  height: 14px;
                  border-radius: 7px;
                  border: 1px solid Red;
                  position: relative;
                  margin-right: 10px;
                  &::after {
                    position: absolute;
                    content: "";
                    top: 3px;
                    left: 3px;
                    border-radius: 50%;
                    width: 6px;
                    height: 6px;
                    background-color: Red;
                  }
                }
              }
            }
            &.identity {
              background-color: #dcf4f6;
            }
            &.inout {
              background-color: #dcf4f6;
            }
            &.host {
              background-color: #dcf4f6;
            }
          }
        }
        &-bottom {
          margin-top: 20px;
          &-item-control {
            margin: 10px 0;
            display: flex;
            align-items: center;
            &-text {
              font-size: 16px;
              &.sunColor {
                color: #8c8942;
              }
              &.nightColor {
                color: #0f3f59;
              }
            }
            label {
              line-height: 0;
            }
            .btn-box {
              display: inline-flex;
              align-items: center;
              width: 40px;
              height: 20px;
              border-radius: 100px;
              background-color: #e6df4c;
              box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.13) inset;
              margin: 0 16px;
            }
            .btn-box .btn {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              line-height: 0;
              background-color: #fff;
              margin-left: 0; /*動畫從ml為0開始*/
              transition: 0.5s;
              box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
            }
            .checkbox {
              position: absolute;
              opacity: 0;
            }
            .checkbox:checked + .btn-box {
              background-color: #0f3f59;
            }
            .checkbox:checked + .btn-box .btn {
              margin-left: 20px;
            }
          }
          &-item {
            &-title {
              font-size: 18px;
              font-weight: 900;
              display: flex;
              align-items: center;
              &-control {
                margin-left: 20px;
                .btn-box {
                  display: inline-block;
                  vertical-align: middle;
                  width: 40px;
                  height: 20px;
                  border-radius: 100px;
                  background-color: #e6df4c;
                  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.13) inset;
                  margin-right: 16px;
                }
                .btn-box .btn {
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background-color: #fff;
                  margin-left: 0; /*動畫從ml為0開始*/
                  transition: 0.5s;
                  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
                }
                .checkbox {
                  position: absolute;
                  opacity: 0;
                }
                .checkbox:checked + .btn-box {
                  background-color: #0f3f59;
                }
                .checkbox:checked + .btn-box .btn {
                  margin-left: 20px;
                }
              }
            }
            padding: 10px;
            &.useing {
              border-radius: 10px;
              background-color: #84d3df;
            }
          }
        }
      }
      &-map {
        margin-left: 15px;
        min-width: 800px;
        min-height: 840px;
        background-color: #c1e8f0;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 10px;
        &-title {
          font-size: 18px;
          font-weight: 900;
          margin-bottom: 20px;
        }
        &-item {
          display: flex;
          height: 770px;
          &-image {
            background: url("~@/assets/taiwan_map.svg") center center / cover
              no-repeat;
            height: 100%;
            width: 450px;
          }
          &-list {
            margin-left: 50px;
            width: 380px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            &-city {
              display: flex;
              border: 1px solid #888;
              border-radius: 5px;
              line-height: 30px;
              padding: 0;
              cursor: pointer;
              &-name {
                width: 60px;
                background-color: #79c6d6;
                padding: 0 5px;
              }
              &-count {
                width: 45px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    &-product {
      height: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding: 0 150px;
      @media (max-height: 1200px) {
        padding: 0 100px;
      }
      &-title {
        margin-top: 120px;
        font-size: 48px;
        line-height: 1.5;
        color: #528cb1;
        @media (max-height: 1400px) {
          font-size: 36px;
          margin-top: 90px;
        }
        @media (max-height: 992px) {
          font-size: 24px;
          margin-top: 60px;
        }
      }
      &-content {
        &-item {
          display: inline-block;
          width: 460px;
          padding: 0 40px;
          box-sizing: border-box;
          &:hover {
            .home-product-content-item-text {
              line-height: 2.35;
            }
          }
          @media (max-height: 1400px) {
            width: 340px;
            padding: 0 30px;
          }
          @media (max-height: 992px) {
            width: 220px;
            padding: 0 20px;
          }
          &-title {
            font-size: 48px;
            line-height: 56px;
            margin-bottom: 40px;
            margin-top: 55px;
            color: #ffee00;
            @media (max-height: 1400px) {
              font-size: 36px;
              line-height: 42px;
              margin-bottom: 30px;
              margin-top: 42px;
            }
            @media (max-height: 992px) {
              font-size: 24px;
              line-height: 28px;
              margin-bottom: 20px;
              margin-top: 30px;
            }
          }
          &-text {
            font-size: 36px;
            line-height: 2;
            @media (max-height: 1400px) {
              font-size: 27px;
            }
            @media (max-height: 992px) {
              font-size: 18px;
            }
          }
          &-img {
            margin-top: 120px;
            margin-bottom: 80px;
            width: 376px;
            @media (max-height: 1400px) {
              margin-bottom: 80px;
              margin-top: 65px;
              width: 280px;
            }
            @media (max-height: 992px) {
              margin-bottom: 40px;
              margin-top: 50px;
              width: 180px;
            }
          }
          &:nth-of-type(1) {
            background-color: #779dde;
            .home-product-content-item-img {
              display: inline-block;
              background: url("~@/assets/product/img_1.svg") center center /
                contain no-repeat;
              height: 160px;
              @media (max-height: 1400px) {
                height: 120px;
              }
              @media (max-height: 992px) {
                height: 90px;
              }
            }
          }
          &:nth-of-type(2) {
            background-color: #77aede;
            .home-product-content-item-img {
              display: inline-block;
              background: url("~@/assets/product/img_2.svg") center center /
                contain no-repeat;
              height: 285px;
              @media (max-height: 1400px) {
                height: 210px;
              }
              @media (max-height: 992px) {
                height: 175px;
              }
            }
          }
          &:nth-of-type(3) {
            background-color: #77d0de;
            .home-product-content-item-img {
              display: inline-block;
              background: url("~@/assets/product/img_3.svg") center center /
                contain no-repeat;
              height: 320px;
              @media (max-height: 1400px) {
                height: 240px;
              }
              @media (max-height: 992px) {
                height: 200px;
              }
            }
          }
          &:nth-of-type(4) {
            background-color: #779dde;
            .home-product-content-item-img {
              display: inline-block;
              background: url("~@/assets/product/img_4.svg") center center /
                contain no-repeat;
              height: 210px;
              @media (max-height: 1400px) {
                height: 135px;
              }
              @media (max-height: 992px) {
                height: 130px;
              }
            }
          }
          &:nth-of-type(5) {
            background-color: #77aede;
            .home-product-content-item-img {
              display: inline-block;
              background: url("~@/assets/product/img_5.svg") center center /
                contain no-repeat;
              height: 395px;
              @media (max-height: 1400px) {
                height: 290px;
              }
              @media (max-height: 992px) {
                height: 260px;
              }
            }
          }
          &:nth-of-type(6) {
            background-color: #779dde;
            .home-product-content-item-img {
              display: inline-block;
              background: url("~@/assets/product/img_6.svg") center center /
                contain no-repeat;
              height: 305px;
              @media (max-height: 1400px) {
                height: 225px;
              }
              @media (max-height: 992px) {
                height: 165px;
              }
            }
          }
          &:nth-of-type(7) {
            background-color: #77d0de;
            .home-product-content-item-img {
              display: inline-block;
              background: url("~@/assets/product/img_7.svg") center center /
                contain no-repeat;
              height: 195px;
              @media (max-height: 1400px) {
                height: 145px;
              }
              @media (max-height: 992px) {
                height: 110px;
              }
            }
          }
        }
      }
    }
    &-about {
      display: flex;
      height: 100%;
      align-items: center;
      padding-right: 50px;
      &-connect {
        width: 630px;
        height: 90%;
        min-height: 600px;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 205px;
        max-height: 800px;
        &-title {
          font-size: 48px;
          line-height: 2;
          color: #528cb1;
          @media (max-height: 992px) {
            font-size: 36px;
          }
        }
        &-content {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;

          &-group {
            display: flex;
            padding-bottom: 25px;
            flex-direction: column;
            justify-content: space-evenly;
            &-item {
              display: flex;
              align-items: center;
              flex-direction: column;
              &-img {
                &.svg-email {
                  background: url("~@/assets/about/email.svg") center center /
                    contain no-repeat;
                }
                &.svg-phone {
                  background: url("~@/assets/about/phone.svg") center center /
                    contain no-repeat;
                }
                &.svg-home {
                  background: url("~@/assets/about/home.svg") center center /
                    contain no-repeat;
                }
                width: 34px;
                height: 34px;
              }
              &-text {
                font-size: 36px;
                line-height: 2;
                @media (max-height: 992px) {
                  font-size: 27px;
                }
              }
            }
          }
        }
      }
      &-map {
        width: 1045px;
        height: 665px;
      }
    }
    #map {
      height: 500px;
    }
    #cityListModal {
      .city-list {
        line-height: 30px;
      }
    }
  }
</style>
